<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-10-09 12:33 -->
<!-- Description:  -->
<template>
  <table class="table table-bordered table-sm">
    <thead>
      <tr>
        <th colspan="2" class="text-center">RESUMEN</th>
      </tr>
      <tr>
        <th>Doctor</th>
        <th>Monto</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(l, i) in group_doctor" :key="i">
        <td>{{ l.name }}</td>
        <td class="text-right">
          <app-span-money :quantity="l.amount_paid" moneyCode="1">
          </app-span-money>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <div class="w-100">
            <canvas height="200px" ref="elCanvas"></canvas>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import _ from "lodash";
import Chart from "chart.js";
// script content
export default {
  name: "ControlTableResumeByEmployee",
  components: {
    //
  },

  // directives

  props: {
    //
  },

  data: () => ({
    group_doctor: [],
    lastChart: null,
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    reset() {
      this.group_doctor = [];
    },
    generate(data) {
      let groupDoctor = _.groupBy(data, "employee_id");
      let groupDoctorResults = [];
      // console.log(groupDoctor);

      for (let keyIt in groupDoctor) {
        // console.log(keyIt);
        let registers = groupDoctor[keyIt];
        let amountPaid = registers.reduce(function (p, c) {
          return p + parseFloat(c.amount_paid);
        }, 0);
        groupDoctorResults.push({
          name: keyIt > 0 ? registers[0].doctor : "Otros",
          employee_id: registers[0].employee_id,
          amount_paid: amountPaid,
        });
      }
      groupDoctorResults = _.orderBy(groupDoctorResults, "amount_paid", "desc");

      if(this.lastChart !== null && this.lastChart) {
        this.lastChart.destroy();
      }

      this.lastChart = new Chart(this.$refs.elCanvas, {
        type: "bar",
        data: {
          labels: groupDoctorResults.map(x => x.name),
          datasets: [
            {label: "Ventas", data: groupDoctorResults.map(x => x.amount_paid), backgroundColor: "green"}
          ],
        },
        animation: {duration: 0}
      });
      this.group_doctor = groupDoctorResults;
    },
  },
};
</script>
<style scoped>
</style>
